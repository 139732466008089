import React from "react";
import { Box, Typography } from "@mui/material";
import ReviewCell from "./ReviewCell";

const Review = ({ avis }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "500", py:2}}>
        Avis{" "}
      </Typography>
      {avis && avis.map((item) => <ReviewCell key={item} avis={item}/>)}
    </Box>
  );
};

export default Review;
