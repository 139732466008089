import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { Icon } from "@iconify/react";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}
function generate2(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "20px",
}));

export default function InteractiveList() {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "1000px" }}>
      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Badges2
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Demo>
            <List dense={dense}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Icon
                      icon="material-symbols:cancel"
                      width="30"
                      height="30"
                      style={{ color: "#f44336" }}
                    />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon
                      icon="fa6-solid:id-card"
                      style={{ color: "FF7900" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Carte d'indentité" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Icon
                      icon="mdi:tick-circle"
                      width="30"
                      height="30"
                      style={{ color: "#66bb6a" }}
                    />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon
                      icon="fluent:vehicle-car-profile-16-filled"
                      style={{ color: "#FF7900" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Permis de conduire" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Icon
                      icon="material-symbols:cancel"
                      width="30"
                      height="30"
                      style={{ color: "#f44336" }}
                    />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon
                      icon="fluent:certificate-16-filled"
                      style={{ color: "#FF7900" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Diplomes" />
              </ListItem>
            </List>
          </Demo>
        </Grid>
        <Grid item xs={12} md={6}>
          <Demo>
            <List dense={dense}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Icon
                      icon="mdi:tick-circle"
                      width="30"
                      height="30"
                      style={{ color: "#66bb6a" }}
                    />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon
                      icon="material-symbols:info"
                      style={{ color: "#FF7900" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Caution morale" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Icon
                      icon="mdi:tick-circle"
                      width="30"
                      height="30"
                      style={{ color: "#66bb6a" }}
                    />{" "}
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon icon="mdi:google-maps" style={{ color: "#FF7900" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Plan de localisation" />
              </ListItem>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Avatar
                      sx={{
                        height: 20,
                        width: 20,
                        p: 2,
                        background: "#FF7900",
                      }}
                    >
                      10
                    </Avatar>
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#FFF2E7" }}>
                    <Icon
                      icon="solar:chat-line-bold"
                      style={{ color: "#FF7900" }}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Avis" />
              </ListItem>
            </List>
          </Demo>
        </Grid>
      </Grid>
    </Box>
  );
}
