import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import RegisterPage from "./pages/register";
import LoginPage from "./auths/views/pages";
import ResetPasswordPage from "./pages/reset-password";
import OtpPage from "./pages/reset-password/OTP";
import NewPasswordPage from "./pages/reset-password/NewPassword";
import BasicLayout from "./components/layouts/BasicLayout";
import HomePage from "./pages/home/Home";
import Boss from "./pages/boss/Boss";
import Grouilleurs from "./pages/grouilleurs/views/Grouilleurs";
import Messages from "./pages/messages/pages/Messages";
import Parametres from "./pages/parametres/Parametres";
import CorbeilleDeTaches from "./pages/corbeille de taches/views/CorbeilleDeTaches";
import Bara from "./pages/bara/views/pages/Bara";
import Badges from "./pages/badges/Badges";
import { Provider } from "react-redux";
import store from "./reducers";
import Detail from "./pages/grouilleurs/views/Detail";
import BaraDetails from "./pages/bara/views/pages/BaraDetails";
import { Toaster } from "react-hot-toast";
import Retraits from "./pages/retraits/retraits";
import Transactions from "./pages/transaction/pages/Transactions";
import DetailsTransaction from "./pages/transaction/pages/details-transaction";
import ProfileGrouilleur from "./pages/profile-grouilleur";

const theme = createTheme({
  palette: {
    background: {
      default: "rgba(249, 249, 249, 1)",
    },
    primary: {
      main: "#00833D",
      light: "#E7FFE7",
      blue: "rgba(15, 88, 174, 1)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF7900",
      contrastText: "#fff",
    },
    ternary: {
      main: "#FFFFFF",
      contrastText: "#000",
    },
    success: {
      main: "#66bb6a",
      light: "#81c784",
      dark: "#388e3c",
      contrastText: "#fff",
    },
    info: {
      main: "#29b6f6",
      light: "#4fc3f7",
      dark: "#0288d1",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffa726",
      light: "#ffb74d",
      dark: "#f57c00",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    contrastText: "#fff",
  },

  typography: {
    fontFamily: ["Poppins"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffffff", // Set the background color to white
        },
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="register/" element={<RegisterPage />} />
            <Route path="home" element={<BasicLayout />}>
              <Route index element={<HomePage />} />
              <Route path="boss/" element={<Boss />} />
              <Route path="bara/" element={<Bara />} />
              <Route path="grouilleurs/" element={<Grouilleurs />} />
              <Route path="grouilleur-details/" element={<Detail />} />
              <Route path="bara-details/" element={<BaraDetails />} />
              <Route path="messages/" element={<Messages />} />
              <Route path="parametres/" element={<Parametres />} />
              <Route path="retraits/" element={<Retraits />} />
              <Route path="corbeille/" element={<CorbeilleDeTaches />} />
              <Route path="badges/" element={<Badges />} />
              <Route path="transactions/" element={<Transactions />} />
              <Route
                path="datails-transaction/"
                element={<DetailsTransaction />}
              />
              <Route path="*" element={<p>404 NOT FOUND</p>} />
            </Route>

            <Route path="grouilleur/:userId" element={<ProfileGrouilleur />} />
            <Route path="reset-password/" element={<ResetPasswordPage />} />
            <Route path="otp/" element={<OtpPage />} />
            <Route path="new-password/" element={<NewPasswordPage />} />
            <Route path="*" element={<p>404 NOT FOUND</p>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
