import { styled, alpha } from '@mui/material';
import { Button, Menu, Paper, Box, Chip } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


export const LoginButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "56px",
  width: "100%",
  fontSize: "18px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "25px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));

export const ResetPasswordButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "56px",
  width: "100%",
  fontSize: "18px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "5px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));
export const DialogButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "46px",
  width: "100%",
  fontSize: "18px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "5px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));

export const AppBarButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "42px",
  width: "167px",
  fontSize: "16px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "6px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));
export const TextBox1 = styled(Box)(({ theme }) => ({
  
    fontWeight: "400",
    fontSize: "14px",
    m: 0,
    color: "rgba(155, 166, 156, 1)",
}));
export const TextBox2 = styled(Box)(({ theme }) => ({
  
    fontWeight: "500",
    fontSize: "14px",
    m: 0,
    color: "#00000",
    textAlign: 'left'
}));
export const TitletBox = styled(Box)(({ theme }) => ({
  
  fontWeight: "700",
  fontSize: "24px",
  m: 0,
}));

export const HomePageButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "42px",
  width: "250px",
  fontSize: "16px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "6px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));
export const AppButton = styled(({ outlined, ...props }) => (
  <Button disableElevation variant={outlined ? "outlined" : "contained"} {...props} />
))(({ theme }) => ({
  
  textTransform: "none",
  height: "42px",
  width: "200px",
  fontSize: "16px",
  color: "var(--White, #FFF)",
  borderRadius: "6px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));
export const HomeTableButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "42px",
  width: "142px",
  fontSize: "16px",
  background: "#FC7900",
  color: "var(--White, #FFF)",
  borderRadius: "6px",
  fontWeight: 400,
  "&:hover": {  background: "rgba(204, 97, 0, 1)", color: "var(--White, #FFF)" },
}));
export const SearchButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  height: "50px",
  width: "100px",
  fontSize: "16px",
  background: "#00833D",
  color: "var(--White, #FFF)",
  borderRadius: "6px",
  fontWeight: 400,
  "&:hover": { background: "#FF7900", color: "var(--White, #FFF)" },
}));


export const StyledOutlinedInput = styled(Button)(({ theme }) => ({
  height: "52px",
  " &:hover": {
    borderColor: "#FC7900",
  },
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: "100px",
    // height: "52px",
    backgroundColor: "#FFF",
    // boxShadow:
    //   "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const GrouilleurPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  minHeight: "540px",
  color: "primary",
  padding: theme.spacing(2),
  // ...theme.typography.body2,
  // textAlign: 'center',
}));
export const BaraPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "330px",
  color:"primary",
  borderRadius:"15px",
  padding: theme.spacing(2),
  // ...theme.typography.body2,
  // textAlign: 'center',
}));
export const ModifiedChip = styled(Chip)(({ theme }) => ({
  size:"large",
  variant:"filled",
  borderRadius: "5px",
  width: "120px",
  height: "35px",
  px: 1,
  backgroundColor: "rgba(194, 233, 198, 1)",
  color: "black",
  fontWeight: "500",

}));


export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}/${(
    dateObj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObj.getFullYear()}`;
  return formattedDate;
};

// export const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   border:"10px",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// export const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// export const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.common.white,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));
