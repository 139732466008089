import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Carousel from "react-bootstrap/Carousel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Toolbar, IconButton, Dialog, DialogContent } from "@mui/material";

const ImageViewer = ({
  images,
  open,
  close,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = useCallback(
    (event) => {
      setIsDragging(true);
      setStartPosition({
        x: event.clientX - dragPosition.x,
        y: event.clientY - dragPosition.y,
      });
    },
    [dragPosition]
  );

  const handleMouseMove = useCallback(
    (event) => {
      if (isDragging) {
        const newX = event.clientX - startPosition.x;
        const newY = event.clientY - startPosition.y;
        setDragPosition({ x: newX, y: newY });
      }
    },
    [isDragging, startPosition]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Reset the drag position to the center (0, 0)
  const resetPosition = () => {
    setDragPosition({ x: 0, y: 0 });
  };

  const handleZoomIn = () =>
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level 3
  const handleZoomOut = () =>
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level 1

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="lg" fullScreen>
        <Toolbar sx={{ bgcolor: "#202124" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 20,
                display: "flex",
                flexDirection: "row",
                gap: 2,
                zIndex: 1,
              }}
            >
              <IconButton
                onClick={handleZoomIn}
                aria-label="zoom-in"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                onClick={handleZoomOut}
                aria-label="zoom-out"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                onClick={resetPosition}
                aria-label="reset-position"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <RefreshIcon />
              </IconButton>
              <IconButton
                component="a"
                aria-label="download"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                onClick={close}
                aria-label="close"
                sx={{
                  color: "#FFF",
                  backgroundColor: "#333",
                  "&:hover": { backgroundColor: "#555" },
                }}
              >
                <CloseIcon sx={{ color: "#FFF" }} />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
        <DialogContent
          sx={{ bgcolor: "#202124", position: "relative", overflow: "hidden" }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <Carousel
            activeIndex={selectedIndex}
            onSelect={(selectedIndex) => setSelectedIndex(selectedIndex)}
            style={{ maxWidth: "100%", height: "95%", overflow: "hidden" }}
            // onSelect={handleCarouselSelect}
          >
            {images &&
              images.map((image, index) => (
                <Carousel.Item key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "80vh",
                      cursor: isDragging ? "grabbing" : "grab",
                    }}
                  >
                    <img
                      src={image.img}
                      alt="Realisation"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        transform: `translate(${dragPosition.x}px, ${dragPosition.y}px) scale(${zoomLevel})`,
                        transformOrigin: "center center",
                        transition: isDragging ? "none" : "transform 0.3s ease",
                      }}
                      onMouseDown={handleMouseDown}
                    />
                  </Box>
                </Carousel.Item>
              ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageViewer;
