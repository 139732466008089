import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Avatar,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { AppButton, TextBox1 } from "../../assets/styles/Widgets";
import Image from "../../assets/img/profile.png";
import { Toolbar } from "yet-another-react-lightbox";
import ProfilePicture from "./components/ProfilePicture";
import Realisation from "./components/Realisation";
import ImageViewer from "./components/ImageViewer";
import Review from "./components/Reviews";
import Badges from "./components/Badges";
import Badges2 from "./components/Badges2";
export const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: 3,
    author: "@bkristastucchio",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: 10,
    author: "@rollelflex_graphy726",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: 27,
    author: "@helloimnik",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: 122,
    author: "@nolanissac",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: 11,
    author: "@hjrc33",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: 16,
    author: "@arwinneil",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: 19,
    author: "@tjdragotta",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: 65,
    author: "@katie_wasserman",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: 12,
    author: "@silverdalex",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: 10,
    author: "@shelleypauls",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: 10,
    author: "@peterlaster",
  },

  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: 12,
    author: "@southside_customs",
  },
];
const avis = [
  {
    name: "Test Chanele",
    text: "Tres professionnel, j'aime ce qu'il fait",
    time: "1 heures",
  },
  {
    name: "Isa John",
    text: "If several languages coalesce, the grammar of the resulting language",
    time: "2 heures",
  },
  {
    name: "Test John",
    text: "Tres professionnel, j'aime ce qu'il fait",
    time: "4 heures",
  },
  {
    name: "Test John",
    text: "Tres professionnel, j'aime ce qu'il fait",
    time: "5 heures",
  },
];
const ProfileGrouilleur = () => {
  const [open, setOpen] = useState(false);
  const { userId } = useParams(); // Get the userId from the URL
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleClose = () => {
    setSelectedIndex(null);
    setOpen(false);
  };
  const handleOpen = (index) => {
    console.log(index);
    setSelectedIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(
          `https://apirecette.jevebara.com/api/mobile/v3/utilisateur/${userId}/profile`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user profile");
        }
        const data = await response.json();
        console.log("userdata:", data.content);
        setUserData(data.content);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [userId]);


  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "background.default",
          px: 2,
          py: 3,
        }}
      >
        <Box
          sx={{ maxWidth: "1000px", textAlign: { xs: "center", md: "left" } }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "24px",
              my: 4,
            }}
          >
            Profil du Grouilleur
          </Typography>

          <ProfilePicture  user={userData} />

          <Box>
            <Badges2 />
            <Divider sx={{ my: 1 }} />
            <Realisation images={itemData} handleOpen={handleOpen} />
            <ImageViewer
              open={open}
              close={handleClose}
              images={itemData}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />

            <Review avis={avis} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileGrouilleur;
