import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Typography } from "@mui/material";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function Realisation({ images, handleOpen }) {
  return (
    <Box sx={{ backgroundcolor: "white" }}>
      <Box sx={{ my: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "500", mb: 2 }}>
          Réalisations
        </Typography>
      </Box>
      <ImageList
        sx={{
          width: { xs: "100%", md: "1000px" },
          borderRadius: "10px",
          maxHeight: 600,
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
        }}
        cols={3}
        rowHeight={200}
        gap={4}
      >
        {images.map((item, index) => {
          const rows = item.featured ? 2 : 1;
          const cols = 2;

          return (
            <ImageListItem key={item.img} rows={rows}>
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
                style={{ borderRadius: "10px" }}
                onClick={() => handleOpen(index)}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                }}
                title={item.title}
                position="bottom"
                actionIcon={
                  <IconButton
                    sx={{ color: "white" }}
                    aria-label={`star ${item.title}`}
                  >
                    <ThumbUpIcon />
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}
