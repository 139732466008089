import axios from 'axios';
import CustomToast from '../../helpers/customToast';

class Api {
  static baseUrl = 'https://apirecette.jevebara.com';
  // static baseUrl = 'https://api.jevebara.com';

  static imageBaseUrl =
    'https://apirecette.jevebara.com/api/file?fileReference=';

  static getToken() {
    const token = sessionStorage.getItem('token');
    if (token === null) return '';
    else return token;
  }

  static async call(dto, successCallBack, errorCallBack) {
    let instance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    try {
      if (dto.method === 'POST') {
        var response = await instance.post(dto.endpoint, dto.data);
        if (response.status === 200 || response.status === 201) {
          if (response.data.code === 200) {
            if (response.data.message) {
              successCallBack(response.data);
            }
          } else {
            CustomToast.showError(response.data.message);
            errorCallBack();
          }
        } else {
          CustomToast.showError(
            "L'opération a échoué, veuillez signaler si le problème persiste."
          );
          errorCallBack();
        }
      } else if (dto.method === 'GET') {
        response = await instance.get(dto.endpoint);
        if (response.status === 200 || response.status === 201) {
          if (response.data.code === 200) {
            successCallBack(response.data);
          } else {
            CustomToast.showError(response.data.message);
            errorCallBack();
          }
        } else {
          CustomToast.showError(
            "L'opération a échoué, veuillez signaler si le problème persiste."
          );
          errorCallBack();
        }
      } else if (dto.method === 'PUT') {
        response = await instance.put(dto.endpoint);
        if (response.status === 200 || response.status === 201) {
          if (response.data.code === 200) {
            successCallBack(response.data);
          } else {
            CustomToast.showError(response.data.message);
            errorCallBack();
          }
        } else {
          CustomToast.showError(
            "L'opération a échoué, veuillez signaler si le problème persiste."
          );
          errorCallBack();
        }
      } else if (dto.method === 'PATCH') {
        try {
          response = await instance.patch(dto.endpoint, dto.data);
          if (response.status === 200 || response.status === 201) {
            if (response.status === 200) {
              CustomToast.showSuccess(response.data.message);
            } else {
              CustomToast.showError(response.data.message);
              errorCallBack();
              console.log('errors');
            }
          } else {
            CustomToast.showError(
              "L'opération a échoué, veuillez signaler si le problème persiste."
            );
            errorCallBack();
          }
        } catch (error) {
          console.error("Erreur lors de l'appel PATCH:", error);
          CustomToast.showError(
            'Une erreur est survenue lors de la mise à jour, veuillez réessayer.'
          );
          errorCallBack();
        }
      } else if (dto.method === 'DELETE') {
        response = await instance.delete(dto.endpoint);
        if (response.status === 200 || response.status === 201) {
          if (response.data.code === 200) {
            successCallBack(response.data);
          } else {
            CustomToast.showError(response.data.message);
            errorCallBack();
          }
        } else {
          CustomToast.showError(
            "L'opération a échoué, veuillez signaler si le problème persiste."
          );
          errorCallBack();
        }
      }
    } catch (error) {
      console.log(error);
      errorCallBack();
      CustomToast.showError(
        "L'opération a échoué, veuillez signaler si le problème persiste."
      );
    }
  }
}

export default Api;



// https://apirecette.jevebara.com/api/mobile/v3/utilisateur/270396/profile