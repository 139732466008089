import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  styled,
 
} from "@mui/material/styles";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { formatDate } from "../../../../assets/styles/Widgets";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const Positionnnements = ({ positionement }) => {
  return (
    <>
      <Box
        sx={{
          fontWeight: "700",
          fontSize: "24px",
          m: 0,
          pt: 6,
        }}
      >
        Positionnements du grouilleur
      </Box>
      <Box sx={{ py: 2 }}>
        {positionement && positionement.length === 0 ? (
          <Box
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              m: 0,
            }}
          >
            Aucun positionement
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date de Creation</StyledTableCell>
                  <StyledTableCell>Commission Grouilleur</StyledTableCell>
                  <StyledTableCell>Commission Boss</StyledTableCell>
                  <StyledTableCell>Montant du Travail</StyledTableCell>
                  <StyledTableCell>Statut</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {positionement.map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell
                      key={row.dateCreation}
                      component="th"
                      scope="row"
                    >
                      {formatDate(row.dateCreation)}
                    </StyledTableCell>
                    <StyledTableCell key={row.montantCommissionGrouilleur}>
                      {row.montantCommissionGrouilleur}
                    </StyledTableCell>
                    <StyledTableCell key={row.montantCommissionBoss}>
                      {row.montantCommissionBoss}
                    </StyledTableCell>
                    <StyledTableCell key={row.montantTravail}>
                      {row.montantTravail}
                    </StyledTableCell>
                    <StyledTableCell key={row.statutGeneral}>
                      {row.statutGeneral}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default Positionnnements;
