import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Icon } from "@iconify/react";
import {
  Box,
  Toolbar,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import Api from "../../../../services/http/api";
import CloseIcon from "@mui/icons-material/Close";


const Realisations = ({ realisations, handleDelete }) => {
  const [openR, setOpenR] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleImageClick = (image, index) => {
    setOpenR(true);
    setActiveIndex(index);
  };

  const handleDeleteClick = (imageId) => {
    setSelectedImageId(imageId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDelete(selectedImageId);
    setConfirmDialogOpen(false);
    setSelectedImageId(null);
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setSelectedImageId(null);
  };

  const containerStyle = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "600px",
    overflow: "hidden",
    boxSizing: "border-box",
  };

  const imageStyle = {
    maxWidth: "100%",
    height: "auto",
  };

  const deleteIconStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "4rem",
    color: "red",
    cursor: "pointer",
    display: "none",
  };

  return (
    <>
      <Box
        sx={{
          fontWeight: "700",
          fontSize: "24px",
          m: 0,
          pt: 6,
        }}
      >
        Realisations du grouilleur
      </Box>
      <Box sx={{ placeItems: "center", mt: 4, width: "100%" }}>
        <Box>
          <Carousel
            data-bs-theme="dark"
            style={{ maxWidth: "100%", overflow: "hidden" }}
          >
            {realisations.map((image, index) => (
              <Carousel.Item interval={1500} key={image.imgPath}>
                <div
                  style={containerStyle}
                  onMouseEnter={(e) => {
                    e.currentTarget.children[0].style.filter = "blur(5px)";
                    e.currentTarget.children[1].style.display = "block";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.children[0].style.filter = "none";
                    e.currentTarget.children[1].style.display = "none";
                  }}
                  onClick={() => handleImageClick(image, index)}
                >
                  <img
                    src={`${Api.baseUrl}/api/file?fileReference=${image.referenceFichier}`}
                    alt={image.referenceFichier}
                    style={imageStyle}
                  />
                  <Icon
                    icon="material-symbols:delete"
                    style={deleteIconStyle}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the full screen on delete icon click
                      handleDeleteClick(image.id);
                    }}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Box>
        <Dialog
          open={openR}
          onClose={() => setOpenR(false)}
          maxWidth="lg"
          fullScreen
        >
          <Toolbar sx={{ bgcolor: "#202124" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <IconButton
                edge="end"
                onClick={() => setOpenR(false)}
                aria-label="close"
              >
                <CloseIcon sx={{ color: "#FFF" }} />
              </IconButton>
            </Box>
          </Toolbar>
          <DialogContent sx={{ bgcolor: "#202124" }}>
            <Carousel
              activeIndex={activeIndex}
              onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
              style={{
                maxWidth: "100%",
                height: "95%",
                overflow: "hidden",
              }}
            >
              {realisations.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    src={`${Api.baseUrl}/api/file?fileReference=${image.referenceFichier}`}
                    alt={image.referenceFichier}
                    style={{
                      width: "100%",
                      height: "100vh",
                      objectFit: "contain",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </DialogContent>
        </Dialog>

        {/* Confirmation Dialog */}
        <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez vous vraiment supprimer cette Realisation?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Annuler
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Realisations;
