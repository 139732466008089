import CryptoJS from "crypto-js";

const ApiUrl = {
  baseUrl: "https://apirecette.jevebara.com",

  register: "api/utilisateur/register-admin",
  login: "/api/login",
  logout: "logout",
  verify: "auth/verify/",
  changePassword: "auth/change_password/",
  resetPassword: "auth/reset_password/",
};

export default ApiUrl;


// Function to encrypt data
export function encryptData(data, secretKey) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
}

// Function to decrypt data
export function decryptData(encryptedData, secretKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
